<!--
 * @Auth: linjituan
 * @Date: 2021-10-27 10:13:26
 * @LastEditors: linjituan
 * @LastEditTime: 2021-10-28 18:25:05
-->
<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="车辆名称">
                <a-input v-model="queryParam.vehicleId" placeholder="请输入车辆名称" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="事件类型" prop="status">
                <a-select placeholder="请选择事件类型" v-model="queryParam.type" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in eventOptions" :key="index" :value="d.dictValue">{{
                    d.dictLabel
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="发生时间">
                  <a-range-picker
                    style="width: 100%"
                    v-model="dateRange"
                    :ranges="{ 今天: [moment(), moment()], 这个月: [moment(), moment().endOf('month')] }"
                    show-time
                    format="YYYY-MM-DD HH:mm:ss"
                    valueFormat="YYYY-MM-DD HH:mm:ss"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery" id="tableQueryBtn"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="operId"
        :columns="columns"
        :data-source="list"
        :pagination="false"
      >
        <span slot="eventType" slot-scope="text, record" :title="eventTypeFormat(record)">
          {{ eventTypeFormat(record) }}
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listEventRecord } from '@/api/iot/eventRecord'
import moment from 'moment'
import { parseDateTime } from '@/utils/ruoyi'

export default {
  name: 'SuddenStopLog',
  components: {},
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      loading: false,
      // 非多个禁用
      multiple: true,
      total: 0,
      // 状态数据字典
      statusOptions: [],
      typeOptions: [],
      // 日期范围
      dateRange: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        vehicleId: this.$route.query.vehicleId,
        // type: '6',
        summaryDate: undefined
      },
      eventOptions: [],
      columns: [
        {
          title: '车辆名称',
          dataIndex: 'vehicleId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '事件类型',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'eventType' },
          align: 'center'
        },
        {
          title: '发生时间',
          ellipsis: true,
          dataIndex: 'happenTime',
          customRender: (text, record, index) => {
            return record.happenTime ? parseDateTime(new Date(record.happenTime), 'yyyy-MM-dd HH:mm:ss:SSS') : ''
          },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    this.getDicts('vehicle_event_type').then(response => {
      this.eventOptions = response.data
    })
  },
  computed: {},
  watch: {
     dateRange(val) {
      if (val) {
        this.queryParam.beginSummaryDate = val[0]
        this.queryParam.endSummaryDate = val[1]
      }
    }
  },
  methods: {
    moment,
    /** 查询登录日志列表 */
    getList() {
      this.loading = true
      listEventRecord(this.queryParam).then(response => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    // 操作日志状态字典翻译
    statusFormat(row, column) {
      return this.selectDictLabel(this.statusOptions, row.status)
    },
    // 操作日志类型字典翻译
    typeFormat(row, column) {
      return this.selectDictLabel(this.typeOptions, row.businessType)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        title: undefined,
        operName: undefined,
        businessType: undefined,
        status: undefined
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.operId)
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    eventTypeFormat(row, column) {
      return this.selectDictLabel(this.eventOptions, row.type)
    }
  }
}
</script>
